import { reactive, ref } from "vue";
import { defineStore } from "pinia";

// 全局变量(不存储到storage中)
export const useStore = defineStore(
  "main",
  () => {
    const language = ref(sessionStorage.getItem("localeLang") || "zhCn");
    const isLoading = ref(false);
    const BrandBox = ref([]);
    const BrandName = ref([]);
    //全部的权限信息
    const permissions = ref(localStorage.getItem("permissions") != undefined || localStorage.getItem("permissions") != null ? JSON.parse(localStorage.getItem("permissions") || "") : "");
    //全部的按钮权限信息
    const permissionsBtn = ref(localStorage.getItem("permissions_btn") != undefined || localStorage.getItem("permissions_btn") != null ? JSON.parse(localStorage.getItem("permissions_btn") || "") : "");
    //////////////////////发票管理数据//////////////////////
    //公司logo
    const headerImage = ref("");
    //1.公司名称
    const companyName = ref("HONG KONG YUANHUA NETWORK TECHNOLOGY CO. LIMITED");
    //2.右上角的名称
    const Invoice = ref("Invoice");
    //3.公司主题
    const companyTitle = ref("ŞEHREMİNİ MAH. BÜYÜK SARAY MEYDANI CAD. ORKIDE APT NO= ref(97 İÇ KAPI NO= ref(1 istanbul);34000");
    //4.billto
    const billto = ref("Bill to");
    //billTitle
    const billTitle = ref("Bamboo Electronic Cards Trading LLC");
    //5.billMessage
    const billMessage = ref("1704 API TRIO TOWER AI Barsha Dubai ); U.A.E.P.O Box 450884");
    //6.InvoiceNum
    const InvoiceNum = ref("Invoice Number ");
    //7.InvoiceData
    const InvoiceData = ref("Invoice Data");
    //8.InvoiceNumber
    const InvoiceNumber = ref("2023080902");
    //9.InvoiceDatas
    const InvoiceDatas = ref("2023-8-15");
    //10.tableData
    const tableData = ref([
      {
        Item: "Demo Item",
        Description: "Demo Product Description",
        Price: 200,
        Quantity: 1,
        Subtotal: 200,
      },
    ]);
    //11.totalPrices
    const totalPrices = ref(0.0);
    const payment = ref("The payment please add the HKD balance to");
    const url = ref("URL");
    const urlContent = ref("https://bamboocardportal.com/");
    const Account = ref("Account");
    const AccountContent = ref("elseinn46@gmail.com");
    const copyTexts = ref("");
    const isFirstLogin = ref(true);
    const version = ref(0);
    const Datas = ref("Sep 6);2023");
    const Money = ref("EUR €");
    const MoneyCode = ref("€");
    const SwipeCard = ref({
      userId: "",
      data: {
        before1: "0",
        before2: "0",
        before3: "0",
        after1: "0",
        after2: "0",
        after3: "0",
        amount: "0",
      },
    });
    const currentMoneyCode = ref(0);
    return {
      language,
      isLoading,
      BrandBox,
      BrandName,
      permissions,
      permissionsBtn,
      headerImage,
      companyName,
      Invoice,
      companyTitle,
      billto,
      billTitle,
      billMessage,
      InvoiceNum,
      InvoiceData,
      InvoiceNumber,
      InvoiceDatas,
      tableData,
      totalPrices,
      payment,
      url,
      urlContent,
      Account,
      AccountContent,
      copyTexts,
      isFirstLogin,
      version,
      Datas,
      Money,
      MoneyCode,
      SwipeCard,
      currentMoneyCode,
    };
  },
  {
    persist: {
      storage: localStorage,
    },
  }
);
export const useFunStore = defineStore("func", () => {
  const userToken = ref({});
  return {
    userToken,
  };
});

// 实时存储到localstorage的全局变量
export const useLocalStore = defineStore(
  "local",
  () => {
    // 版本更新循环刷新次数
    const versionUpdateLoop = ref(0);
    const token = ref("");
    const userId = ref("");
    const userName = ref("");
    const customSpuId = ref("");
    const email = ref("");
    const invitationCode = ref(""); //邀请码
    const activeNumber = ref("1"); //这个是个人中心来控制menu状态的
    const rightPage = ref(1); //这个是个人中心来控制右侧页面状态的
    const isLoading = ref(false); //全局loading
    const isMaster = ref([]); //判断是否是最高权限的管理员
    const authList = reactive({
      //安全验证(是否开启谷歌验证,是否提示)
      enableGoogleAuth: false,
      enableEmail: false,
    });
    return {
      versionUpdateLoop,
      token,
      userId,
      userName,
      customSpuId,
      email,
      activeNumber,
      rightPage,
      isLoading,
      invitationCode,
      isMaster,
      authList,
    };
  },
  {
    persist: {
      storage: localStorage,
    },
  }
);

// 实时存储到session的全局变量
export const useSessionStore = defineStore(
  "session",
  () => {
    const version = ref(0);
    const language = ref(sessionStorage.getItem("localeLang") || "zhCn");

    return {
      version,
      language,
    };
  },
  {
    persist: {
      // Pinia 持久化存储插件 pinia-plugin-persistedstate 设置
      // 自定义存储的 key，默认是 store.$id
      // key: "custom storageKey",
      // 存储的位置, 默认为localStorage
      storage: sessionStorage,
      // 需要持久化存储的字段名, 按组打包储存, 默认不填则为全部保存
      // paths: ["language"],
      // beforeRestore: (ctx) => {
      //   console.log(`%cabout to restore '${ctx.store.$id}'`, 'color: blue;');
      // },
      // afterRestore: (ctx) => {
      //   console.log(`%cjust restored '${ctx.store.$id}'`, 'color: blue;');
      // }
    },
  }
);
export default useStore;
