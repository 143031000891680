import { createRouter, createWebHashHistory } from "vue-router";
import type { RouteRecordRaw } from "vue-router";

import { ElMessage } from "element-plus";
import { versionCheck } from "@/hooks/versionCheck";
import { useLocalStore } from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    children: [
      {
        path: "",
        name: "welcomeView",
        component: () => import("@/views/WelcomeView.vue"),
      },
      {
        path: "platform",
        name: "platform",
        component: () => import("@/layout/userInfo/platForm/platFormView.vue"),
      },
      {
        path: "Wallet",
        name: "Wallet",
        component: () => import("@/layout/userInfo/wallet/walletView.vue"),
      },
      {
        path: "rechargeHistory",
        name: "rechargeHistory",
        component: () => import("@/layout/userInfo/wallet/RechargeHistory.vue"),
      },
      {
        path: "operateHistory",
        name: "operateHistory",
        component: () => import("@/layout/userInfo/wallet/operateHistory.vue"),
      },
      {
        path: "Brand",
        name: "Brand",
        component: () => import("@/layout/product/BrandManage/brandView.vue"),
      },
      {
        path: "spu",
        name: "spu",
        component: () => import("@/layout/product/SPUManage/spuView.vue"),
      },
      {
        path: "customManage",
        name: "customManage",
        component: () => import("@/layout/product/CustomManage/skuCustom.vue"),
      },
      {
        path: "outboundSyn",
        name: "outboundSyn",
        component: () => import("@/layout/product/OutboundSyn/OutboundIndex.vue"),
      },
      {
        path: "orderDetailInfo",
        name: "orderDetailInfo",
        component: () => import("@/layout/order/orderViewB.vue"),
      },
      {
        path: "orderDetailInfoToC",
        name: "orderDetailInfoToC",
        component: () => import("@/layout/order/orderViewC.vue"),
      },
      {
        path: "orderStatistics",
        name: "orderStatistics",
        component: () => import("@/layout/order/orderStatisticsView.vue"),
      },
      {
        path: "deliveredSku",
        name: "deliveredSku",
        component: () => import("@/layout/order/deliveredSku.vue"),
      },

      {
        path: "workersManage",
        name: "workersManage",
        component: () => import("@/layout/commission/staffView.vue"),
      },
      {
        path: "visitorInfo",
        name: "visitorInfo",
        component: () => import("@/layout/commission/visitorView.vue"),
      },
      //员工客户积分(表)
      {
        path: "EmployeeCustomerPointsTable",
        name: "EmployeeCustomerPointsTable",
        component: () => import("@/layout/commission/staffCustomersSheet.vue"),
      },
      //员工开发客户积分统计（统计图）
      {
        path: "EmployeeCustomerPointsFigure",
        name: "EmployeeCustomerPointsFigure",
        component: () => import("@/layout/commission/staffCustomersChart.vue"),
      },
      //员工开发客户积分统计（弹框）
      {
        path: "EmployeeCustomerDialog",
        name: "EmployeeCustomerDialog",
        component: () => import("@/layout/commission/staffCustomersDialog.vue"),
      },
      //员工利润业绩(表)
      {
        path: "EmployeeProfitPerformanceTable",
        name: "EmployeeProfitPerformanceTable",
        component: () => import("@/layout/commission/staffProfitSheet.vue"),
      },
      //员工利润业绩(图)
      {
        path: "EmployeeProfitPerformanceFigure",
        name: "EmployeeProfitPerformanceFigure",
        component: () => import("@/layout/commission/staffProfitChart.vue"),
      },
      //员工月提成明细
      {
        path: "EmployeeMonthlyCommissionDetails",
        name: "EmployeeMonthlyCommissionDetails",
        component: () => import("@/layout/commission/staffCommissionDetails.vue"),
      },
      //客户数据分析
      {
        path: "customerDataAnalysis",
        name: "customerDataAnalysis",
        component: () => import("@/layout/commission/customerDataView.vue"),
      },
      //员工公共奖金池
      {
        path: "staffBonus",
        name: "staffBonus",
        component: () => import("@/layout/commission/staffBonus.vue"),
      },
      {
        path: "customerConfig",
        name: "customerConfig",
        component: () => import("@/layout/commission/configurationView.vue"),
      },
      {
        path: "workersSeek",
        name: "workersSeek",
        component: () => import("@/layout/commission/commissionView.vue"),
      },
      {
        path: "workersMeter",
        name: "workersMeter",
        component: () => import("@/layout/commission/achievementView.vue"),
      },
      {
        path: "purchaseRoyalty",
        name: "purchaseRoyalty",
        component: () => import("@/layout/commission/purchaseRoyaltyView.vue"),
      },
      {
        path: "C2cOrder",
        name: "C2cOrder",
        component: () => import("@/layout/C2C/C2cOrderView.vue"),
      },
      {
        path: "sellTask",
        name: "sellTask",
        component: () => import("@/layout/C2C/C2cSellTask.vue"),
      },
      {
        path: "buyTask",
        name: "buyTask",
        component: () => import("@/layout/C2C/C2cBuyTask.vue"),
      },
      {
        path: "roles",
        name: "roles",
        component: () => import("@/layout/system/roleManagement.vue"),
      },
      //系统工具
      {
        path: "PSNCheck",
        name: "PSNCheck",
        component: () => import("@/layout/sysUtil/PSNCheck.vue"),
      },
      {
        path: "PSNAccountManage",
        name: "PSNAccountManage",
        component: () => import("@/layout/sysUtil/PSNAccountManage.vue"),
      },
      {
        path: "supplierManage",
        name: "supplierManage",
        component: () => import("@/layout/purchase/purchaseList.vue"),
      },
      {
        path: "supplierProduct",
        name: "supplierProduct",
        component: () => import("@/layout/purchase/purchaseContents.vue"),
      },
      //商品映射管理
      {
        path: "productMapping",
        name: "productMapping",
        component: () => import("@/layout/purchase/productMapping.vue"),
      },
      //采购菜单
      {
        path: "purchaseOrder",
        name: "purchaseOrder",
        component: () => import("@/layout/purchase/purchaseOrder.vue"),
      },
      //自动补货
      {
        path: "autoPurchase",
        name: "autoPurchase",
        component: () => import("@/layout/purchase/restockView.vue"),
      },
      //入库查询
      {
        path: "inventoryList",
        name: "inventoryList",
        component: () => import("@/layout/purchase/inventoryList.vue"),
      },
      //出库查询
      {
        path: "outboundList",
        name: "outboundList",
        component: () => import("@/layout/purchase/outboundList.vue"),
      },
      //卡密
      {
        path: "passwordSearch",
        name: "passwordSearch",
        component: () => import("@/layout/purchase/passwordSearch.vue"),
      },
      //老平台的库存列表迁移
      {
        path: "stockList",
        name: "stockList",
        component: () => import("@/layout/purchase/stockList.vue"),
      },
      //老平台的库库存明细迁移
      {
        path: "stockDetail",
        name: "stockDetail",
        component: () => import("@/layout/purchase/stockDetail.vue"),
      },
      //发票
      {
        path: "invoice",
        name: "invoice",
        // component: () => import("@/layout/invoice/invoiceView.vue"),
        component: () => import("@/layout/invoice/IssueInvoice.vue"),
      },
      //发票预览
      {
        path: "invoicePreview",
        name: "invoicePreview",
        component: () => import("@/layout/invoice/invoicePreview.vue"),
      },
      //belo
      {
        path: "belo",
        name: "belo",
        component: () => import("@/layout/belo/BeloManage.vue"),
      },
      //日本卡兑换
      {
        path: "japanCode",
        name: "japanCode",
        component: () => import("@/layout/japanCode/japanCode.vue"),
      },
      {
        path: "emailOrder",
        name: "emailOrder",
        component: () => import("@/layout/emailOrder/EmailOrder.vue"),
      },
      //刷卡管理-账号管理
      {
        path: "swipeAccount",
        name: "swipeAccount",
        component: () => import("@/layout/swipe/SwipeAccount.vue"),
      },
      //刷卡管理-刷单历史
      {
        path: "swipeHistroy",
        name: "swipeHistroy",
        component: () => import("@/layout/swipe/SwipeHistroy.vue"),
      },
      //刷卡管理-刷单策略
      {
        path: "swipeStrategy",
        name: "swipeStrategy",
        component: () => import("@/layout/swipe/swipeStrategy.vue"),
      },
      //刷卡管理-刷卡分组
      {
        path: "swipeGroup",
        name: "swipeGroup",
        component: () => import("@/layout/swipe/SwipeGroup.vue"),
      },
      {
        path: "approveList",
        name: "approveList",
        component: () => import("@/layout/approve/ApproveList.vue"),
      },
      //网易充值
      {
        path: "neteaseOrder",
        name: "neteaseOrder",
        component: () => import("@/layout/netEaseRecharge/netEaseRecharge.vue"),
      },
      //轮播图管理
      {
        path: "rotatingChart",
        name: "rotatingChart",
        component: () => import("@/layout/rotatingChart/RotatingChart.vue"),
      },
      //认证审核
      {
        path: "authenticate",
        name: "authenticate",
        component: () => import("@/layout/userInfo/authenticateView/authenticateView.vue"),
      },

      //个人中心
      {
        path: "profile",
        name: "profile",
        component: () => import("@/layout/userInfo/personalBox/profileView.vue"),
      },
      // 汇率告警
      {
        path: "exchangeRateAlerts",
        name: "exchangeRateAlerts",
        component: () => import("@/layout/toolkit/ExchangeRateAlerts.vue"),
      },
      //系统参数
      {
        path: "systemParameters",
        name: "systemParameters",
        component: () => import("@/layout/system/systemParameters.vue"),
      },
      //工单管理 -- 客服回复
      {
        path: "customerReply",
        name: "customerReply",
        component: () => import("@/layout/C2C/WorkOrderCustomerReply.vue"),
      },
      //b端合作申请审核
      {
        path: "cooperationApplication",
        name: "cooperationApplication",
        component: () => import("@/layout/userInfo/cooperationView/cooperationIndex.vue"),
      },
      //xbox 历史订单
      {
        path: "xboxHistroyOrder",
        name: "xboxHistroyOrder",
        component: () => import("@/layout/xbox/XboxHistroyOrder.vue"),
      },
      // razer Goods 雷蛇奖品管理
      {
        path: "razerGoods",
        name: "razerGoods",
        component: () => import("@/layout/razer/RazerGoods.vue"),
      },
      //xbox账号管理
      {
        path: "xboxAccounts",
        name: "xboxAccounts",
        component: () => import("@/layout/xbox/xboxAccounts.vue"),
      },
      //xbox地址模版
      {
        path: "xboxAdderssTemp",
        name: "xboxAdderssTemp",
        component: () => import("@/layout/xbox/xboxAddress.vue"),
      },
      //xbox采购管理
      {
        path: "procurementInfo",
        name: "procurementInfo",
        component: () => import("@/layout/xbox/xboxProcurementInfo.vue"),
      },
      //xbox经营数据
      {
        path: "operatingData",
        name: "operatingData",
        component: () => import("@/layout/xbox/operatingData.vue"),
      },
      //xbox的采购批次管理页面
      {
        path: "purManagement",
        name: "purManagement",
        component: () => import("@/layout/xbox/xboxBatchManagement.vue"),
      },
      //xbox的仓库管理
      {
        path: "warManagement",
        name: "warManagement",
        component: () => import("@/layout/xbox/warManagement.vue"),
      },
      //xbox的出售批次管理
      {
        path: "saleManagement",
        name: "saleManagement",
        component: () => import("@/layout/xbox/saleManagement.vue"),
      },
      //大客户自动补货关联关系
      {
        path: "majorReplenishment",
        name: "majorReplenishment",
        component: () => import("@/layout/majorClient/MajorReplenishment.vue"),
      },
      //大客户商品库存管理
      {
        path: "majorGoods",
        name: "majorGoods",
        component: () => import("@/layout/majorClient/MajorGoods.vue"),
      },
      //大客户补货单管理
      {
        path: "replenishmentOrder",
        name: "replenishmentOrder",
        component: () => import("@/layout/majorClient/ReplenishmentOrder.vue"),
      },
      //老后台迁移(价格改动)
      {
        path: "priceChanges",
        name: "priceChanges",
        component: () => import("@/layout/product/PriceContent/prcieChange.vue"),
      },
      //老后台迁移(改价记录)
      {
        path: "priceChangesRecord",
        name: "priceChangesRecord",
        component: () => import("@/layout/product/PriceContent/priceChangesRecord.vue"),
      },
      //humbel 游戏购买-账号管理
      {
        path: "humbleGameAccounts",
        name: "humbleGameAccounts",
        component: () => import("@/layout/humbleGame/HumbleGameAccounts.vue"),
      },
      //humbel 游戏购买-采购管理 humbleGameBuy
      {
        path: "humbleGameBuy",
        name: "humbleGameBuy",
        component: () => import("@/layout/humbleGame/HumbleGameBuy.vue"),
      },
      //humbel 游戏购买-visa管理 humbleGameVisa
      {
        path: "humbleGameVisa",
        name: "humbleGameVisa",
        component: () => import("@/layout/humbleGame/HumbleGameVisa.vue"),
      },
      //加款审核 additionsReview
      {
        path: "additionsReview",
        name: "additionsReview",
        component: () => import("@/layout/additionsReview/AdditionsReview.vue"),
      },
      {
        path: "example",
        name: "example",
        component: () => import("../layout/example.vue"),
      },
      //c2c 安全协议配置
      {
        path: "c2cSecurityProtocol",
        name: "c2cSecurityProtocol",
        component: () => import("@/layout/sysUtil/C2CSecurityProtocol.vue"),
      },
      //库存申报下的补货订单
      {
        path: "replenishment",
        name: "replenishment",
        component: () => import("@/layout/inventoryDeclaration/replenishmentOrder.vue"),
      },
      //库存申报下的平台利润图表
      {
        path: "profitChat",
        name: "profitChat",
        component: () => import("@/layout/inventoryDeclaration/profitChat.vue"),
      },
      // carrypin point 权益管理 cpTask
      {
        path: "cpTask",
        name: "cpTask",
        component: () => import("@/layout/carrypinPoint/CpTask.vue"),
      },
      //carrypin point 权益管理 cpInterests
      {
        path: "cpInterests",
        name: "cpInterests",
        component: () => import("@/layout/carrypinPoint/CpInterests.vue"),
      },
      //carrypin point 活动规则 cpManeuver
      {
        path: "cpManeuver",
        name: "cpManeuver",
        component: () => import("@/layout/carrypinPoint/CpManeuver.vue"),
      },
      //carrypin point cp钱包 cpwallet
      {
        path: "cpwallet",
        name: "cpwallet",
        component: () => import("@/layout/carrypinPoint/CpWallet.vue"),
      },
      //商品下的库存统计
      {
        path: "inventoryStatistics",
        name: "inventoryStatistics",
        component: () => import("@/layout/product/Inventory/inventoryStatistics.vue"),
      },
      //商品下的库存指示灯
      {
        path: "InventoryLight",
        name: "InventoryLight",
        component: () => import("@/layout/product/Inventory/inventoryLight.vue"),
      },
      //订单管理下的退款管理
      {
        path: "2CRefund",
        name: "2CRefund",
        component: () => import("@/layout/order/2CRefund.vue"),
      },
      //微软商品展示
      {
        path: "xboxGoods",
        name: "xboxGoods",
        component: () => import("@/layout/xbox/xboxGoods.vue"),
      },
      //商品管理下的广告管理
      {
        path: "adManagment",
        name: "adManagment",
        component: () => import("@/layout/product/adManagment/adManagment.vue"),
      },
      //商品管理下的筛选器推荐
      {
        path: "filtersManagment",
        name: "filtersManagment",
        component: () => import("@/layout/product/FiltersManagment/filtersManagment.vue"),
      },
      //商品管理下的游戏
      {
        path: "gamePlatFormManagement",
        name: "gamePlatFormManagement",
        component: () => import("@/layout/product/gamePlatFormManagement/gamePlatFormManagement.vue"),
      },
    ],
  },
  //全局工具包
  {
    path: "/toolkit",
    name: "toolkit",
    component: () => import("@/layout/toolkit/ToolkitComponent.vue"),
    children: [
      //刷卡金额计算器
      {
        path: "/swipeCardCalculator",
        name: "swipeCardCalculator",
        component: () => import("@/layout/toolkit/SwipeCardAmountCalculator.vue"),
      },
      //刷卡账号格式化工具
      {
        path: "/swipeCardFromat",
        name: "swipeCardFromat",
        component: () => import("@/layout/toolkit/SwipeCardFromat.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)", // 其他URl跳转404页面
    redirect: "/404",
    children: [
      {
        path: "/:pathMatch(404)", // 和上面的redirect对应,必须加上/
        name: "404",
        component: () => import("@/views/notFound.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  Promise.all([
    // router事件函数
    (() => {
      const localStore = useLocalStore();
      if (to.path == "/" || to.path == "/login" || to.path == "/register") {
        // 登录或者注册才可以往下进行
        next();
      } else {
        // 获取 token
        const token = localStore.token;
        // token 不存在
        if (!token) {
          ElMessage.error("您还没有登录，请先登录");
          next("/");
        } else {
          next();
        }
      }
      return true;
    })(),
    versionCheck(to),
  ]);
});
export default router;
